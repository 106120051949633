import { Level, LevelConfig } from "./levels";
import { debugLog } from "./main";
import { disableTouch } from "./Platform";
import { Game, Platform } from "./GamePlatform";
import { ASTemplate } from "./Template";
import { Tile } from "./Tile";

type TileDeck = Array<Tile>;
type TileGrid = Array<Array<Tile>>;
type GameState = "init" | "ready" | "playing" | "gameover";
export class Memory implements Game {
	private debug: boolean = false;
	private currentLevel: Level;
	private allLevels: Array<Level>;
	private currentTimer: number;
	private currentScore: number = 0;
	private currentTimeLeft: number;
	private multiplier: number = 0;
	private loops: number = 0;
	private score: number = 0;
	private firstPick: Tile;
	private secondPick: Tile;
	private unmatchedPairs: number;
	private flipping: boolean = false;

	private tileGrid: TileGrid;

	private elmTable: HTMLTableElement;
	private elmCanvas: HTMLDivElement;
	private elmTest: HTMLDivElement;
	private elmBar: HTMLDivElement;
	private elmScore: HTMLSpanElement;

	private templates = {
		game: new ASTemplate("template-game"),
		card: new ASTemplate("template-card"),
	};

	private platform: Platform;

	private state: GameState = "init";
	private levelState: "init" | "started" | "complete";
	private images: Array<HTMLImageElement> = [];
	private imageLoadCount = 0;
	private config = {
		nextLevelTimeout: 2000,

		fontColor: "#000",
		fontOffsetY: 0,
		fontSize: 30,
		scoreBarColor: "#499D2E",
		scoreBarBackgroundColor: "#499D2E",

		scoreBarBorderColor: "#000",
		scoreBarBorderRadius: 5,
		scoreBarBorderWidth: 3,
		scoreBarOffsetY: 0,
		cardFrontBorderColor: "#000",
		cardBackBorderColor: "#000",
		cardBorderRadius: 10,
		cardBorderWidth: 10,
		cardFrontBackgroundColor: "#fff",

		gridOffsetY: 0,

		backgroundImage: "vuur.jpeg",

		cardSize: 3000,
		cardMargin: 2,
		maxColumns: 4,
		levels: "",
		unflipTime: 500,
		checkInterval: 500,
		maxLoops: 0,
		scorePerMatch: 100,
		bonusPerMs: 10,
		speedUp: 10, // 10 seconds less
		path: "https://gamecdn.playenwin.com/wietse/memory/default/",
		font: "Acme",

		tileSelectSound: "",
		matchSound: "",
		noMatchSound: "",
		timeBonusSound: "",
	};

	constructor() {
		if (window.location.search.search("debug") !== -1) {
			this.debug = true;
		}
		disableTouch();
		this.loadConfig();

		if (this.config.levels) {
			this.loadLevels();
		} else {
			this.allLevels = LevelConfig;
		}

		this.init();
	}

	private loadConfig() {
		const urlParams = new URLSearchParams(window.location.search);

		// Patch settings
		if (urlParams.get("s")) {
			const settings = atob(urlParams.get("s") || "")
				.split("\n")
				.filter((s) => s.trim() !== "")
				.map((s) => {
					return {
						name: s.split("=")[0].trim(),
						value: s.split("=")[1].trim(),
					};
				});
			settings.forEach((s) => {
				if (!isNaN(+s.value)) {
					this.config[s.name] = +s.value;
				} else if (s.value === "true") {
					this.config[s.name] = true;
				} else if (s.value === "false") {
					this.config[s.name] = false;
				} else {
					this.config[s.name] = s.value;
				}
			});
		}
	}

	private loadLevels() {
		this.allLevels = [];
		const rawLevels = this.config.levels.split(";");
		rawLevels.forEach((rawLevel) => {
			if (rawLevel === "") {
				return;
			}
			const levelData = rawLevel.split(",");
			const time = +levelData.splice(0, 1);
			const cards = levelData;
			const level = {
				level: this.allLevels.length + 1,
				tiles: cards.map((c) => c.trim()),
				time: time,
			} as Level;
			this.allLevels.push(level);
		});
	}

	private init() {
		this.platform = new Platform();

		this.platform.preloadSounds(this.config);

		this.elmCanvas = document.getElementById("canvas") as HTMLDivElement;

		this.templates.game.addTo(this.elmCanvas);
		this.elmTable = this.elmCanvas.querySelector(
			"#cardsContainer"
		) as HTMLTableElement;

		this.elmBar = this.elmCanvas.querySelector("#myBar") as HTMLDivElement;

		this.elmCanvas.querySelector("#myProgress").style.top =
			50 + this.config.scoreBarOffsetY + "px";

		this.elmScore = this.elmCanvas.querySelector(
			"#score"
		) as HTMLSpanElement;
		this.elmScore.style.fontFamily = this.config.font;
		this.elmScore.style.fontSize = this.config.fontSize + "px";
		this.elmScore.style.top = this.config.fontOffsetY + "px";
		this.elmTest = this.elmCanvas.querySelector("#test") as HTMLDivElement;
		this.elmTest.style.top = 100 + this.config.gridOffsetY + "px";

		(<HTMLElement>(
			this.elmCanvas.querySelector("#myProgress")
		)).style.borderColor = this.config.scoreBarBorderColor;
		(<HTMLElement>(
			this.elmCanvas.querySelector("#myProgress")
		)).style.borderWidth = this.config.scoreBarBorderWidth + "px";
		(<HTMLElement>(
			this.elmCanvas.querySelector("#myProgress")
		)).style.borderRadius = this.config.scoreBarBorderRadius + "px";

		this.elmBar.style.backgroundColor = this.config.scoreBarColor;
		(<HTMLElement>(
			this.elmCanvas.querySelector("#myBarBackground")
		)).style.backgroundColor = this.config.scoreBarBackgroundColor;

		document.body.style.color = this.config.fontColor;

		const imageNames = [];
		this.allLevels.forEach((l) => {
			l.tiles.forEach((tileName) => {
				if (imageNames.indexOf(tileName) === -1) {
					imageNames.push(tileName);
				}
			});
		});

		const cache = this.debug ? "?" + Date.now() : "";

		imageNames.forEach((imagename) => {
			let img = new Image();
			img = new Image();
			img.src = this.config.path + "/" + imagename;
			img.onload = (e) => this.checkImages();
			this.images.push(img);
		});
		let img = new Image();
		img = new Image();
		img.src = this.config.path + "/" + this.config.backgroundImage + cache;
		img.onload = (e) => this.checkImages();
		this.images.push(img);
	}

	private checkImages() {
		this.imageLoadCount++;
		if (this.imageLoadCount === this.images.length) {
			this.initReady();
			this.prepareGame();
		}
	}

	private initReady() {
		this.platform.init(this, this.config);
	}

	restart() {
		this.prepareGame();
	}

	private prepareGame() {
		this.elmBar.classList.remove("disabled");
		this.currentLevel = null;
		this.multiplier = 0;
		this.currentScore = 0;
		this.setScore(0);
		this.loops = 1;

		// Create tiles in grid
		this.prepareLevel();
		this.state = "ready";
	}

	private prepareLevel() {
		if (!this.loadNextTileDeck()) {
			this.gameover();
			// console.log('Game ended');
			return;
		}

		this.firstPick = null;
		this.secondPick = null;
		this.elmBar.classList.remove("fastBar");
		this.elmBar.classList.remove("emptyBar");
		this.elmBar.classList.add("refillBar");
		this.elmBar.style.width = "100%";

		this.loadGrid();
		this.showGrid();
		this.unmatchedPairs = this.currentLevel.tiles.length;
		this.levelState = "init";
		this.currentTimeLeft = this.currentLevel.time;
	}

	public play() {
		if (this.state !== "ready") {
			return;
		}
		debugLog("start playing");
		this.state = "playing";
	}

	// For PlayAndWinPlatForm
	public mute(mute: boolean) {
		Howler.mute(mute);
	}

	private startTimer() {
		if (this.levelState === "started") {
			return;
		}

		this.currentTimeLeft = this.currentLevel.time;
		this.levelState = "started";
		this.startTimerBar(this.timesUp.bind(this));
	}
	private stopTimer() {
		debugLog("stop timer called");
		window.clearInterval(this.currentTimer);
	}

	private timesUp(e) {
		if (this.levelState === "started") {
			this.gameover();
		} else {
			// should already be going to next level. Just in time
		}
	}

	private gameover() {
		// Set to gameover state
		this.state = "gameover";

		console.log("Score: ", this.currentScore);
		this.platform.gameover(Math.round(this.currentScore));
	}

	private showGrid() {
		const rows = this.tileGrid.length;
		const cols = this.tileGrid[0].length;
		let cardSize = 60;
		switch (cols) {
			case 1:
				cardSize = 150;
				break;
			case 2:
				cardSize = 100;
				break;
			case 3:
				cardSize = 80;
				break;
			case 4:
				cardSize = 70;
				break;
			case 5:
				cardSize = 60;
				break;
		}
		if (rows > 5) {
			cardSize = 50;
		}
		if (rows > 6) {
			cardSize = 45;
		}
		if (rows > 7) {
			cardSize = 40;
		}
		this.config.cardSize = cardSize;

		// Clean table
		this.elmTable.innerHTML = "";

		this.elmTest.innerHTML = "";
		// Add cells to table
		this.tileGrid.forEach((gridrow) => {
			const tableRow = document.createElement("div");
			gridrow.forEach((tile) => {
				const tableCell = document.createElement(
					"div"
				) as HTMLTableCellElement;
				this.templates.card.addTo(tableCell);
				const urlBack = this.config.path + "/" + tile.title;
				const urlFront =
					this.config.path + "/" + this.config.backgroundImage;
				(tableCell.querySelector(".back") as HTMLImageElement).src =
					urlBack; //  "url('assets/img/" + tile.title + ".png')";
				(tableCell.querySelector(".front") as HTMLImageElement).src =
					urlFront;
				tile.setElement(tableCell);
				(<HTMLImageElement>(
					tableCell.querySelector(".card .front")
				)).style.borderColor = this.config.cardBackBorderColor;
				(<HTMLImageElement>(
					tableCell.querySelector(".card .back")
				)).style.borderColor = this.config.cardFrontBorderColor;

				(<HTMLImageElement>(
					tableCell.querySelector(".card .back")
				)).style.borderRadius = this.config.cardBorderRadius + "px";
				(<HTMLImageElement>(
					tableCell.querySelector(".card .front")
				)).style.borderRadius = this.config.cardBorderRadius + "px";

				(<HTMLImageElement>(
					tableCell.querySelector(".card .front")
				)).style.borderWidth = this.config.cardBorderWidth + "px";
				(<HTMLImageElement>(
					tableCell.querySelector(".card .back")
				)).style.borderWidth = this.config.cardBorderWidth + "px";

				// (<HTMLImageElement>tableCell.querySelector('.card img')).style.borderWidth = this.config.cardBorderWidth + 'px';
				(<HTMLImageElement>(
					tableCell.querySelector(".card .back")
				)).style.backgroundColor = this.config.cardFrontBackgroundColor;

				(<HTMLImageElement>(
					tableCell.querySelector(".container")
				)).style.width = this.config.cardSize + "px";
				(<HTMLImageElement>(
					tableCell.querySelector(".container")
				)).style.height = this.config.cardSize + "px";
				(<HTMLImageElement>(
					tableCell.querySelector(".container")
				)).style.margin = this.config.cardMargin + "px";
				tableRow.appendChild(tableCell);
			});
			this.elmTest.appendChild(tableRow);
		});
	}

	public handleTileClick(tile: Tile) {
		if (this.state !== "playing") {
			debugLog("Game is not yet started State: " + this.state);
			return;
		}

		// If first click on a card, the timer should be started
		if (this.levelState === "init") {
			this.platform.gamestarted();
			this.platform.resume();
			this.startTimer();
		}

		// Already completed. Do nothing
		if (this.levelState === "complete") {
			return;
		}

		// Already 2 cards in action. Do nothing
		if (this.secondPick) {
			return; // still active second
		}

		// First card
		if (!this.firstPick) {
			this.playSound("tileSelect");
			this.firstPick = tile;
			tile.flip();
			return;
		}

		// Second card clicked
		// Register card and show flip
		this.secondPick = tile;
		if (this.firstPick.isSame(this.secondPick)) {
			this.playSound("tileSelect");
			this.firstPick.unflip();
			window.setTimeout((e) => {
				this.firstPick = null;
				this.secondPick = null;
			}, this.config.unflipTime);
			return;
		}

		tile.flip();
		this.playSound("tileSelect");

		this.flipping = true;

		// If no match, unflip after 500ms
		if (!this.firstPick.isMatch(this.secondPick)) {
			window.setTimeout((e) => {
				this.firstPick.unflip();
				this.secondPick.unflip();
				this.firstPick = null;
				this.secondPick = null;
				this.playSound("noMatch");
				this.flipping = false;
			}, this.config.unflipTime);
			// no further actions until unflip is performed
			return;
		}

		// Match is made, show animation after css delay, cards are disabled after this
		this.firstPick.match();
		this.secondPick.match();

		setTimeout(() => {
			this.playSound("match");
			this.currentScore += this.config.scorePerMatch;
			this.setScore(this.currentScore);
			this.flipping = false;
		}, 500);

		this.firstPick = null;
		this.secondPick = null;

		// Register hit
		this.unmatchedPairs--;

		// Add score

		// Show score
		this.setScore(this.currentScore);

		if (this.unmatchedPairs > 0) {
			// Continue with next cards
			return;
		}

		this.levelState = "complete";
		this.platform.pause();

		// Stop timer
		this.stopTimer();

		// Wait for 1000ms and start bringing down the score
		window.setTimeout((e) => {
			this.emptyBar(this.LevelComplete.bind(this));
		}, this.config.unflipTime * 2);
	}

	// Called when scorebar is back to 0 and points are added
	private LevelComplete() {
		//  Make score final and show
		this.currentScore += Math.max(
			0,
			this.currentTimeLeft * this.config.bonusPerMs
		);
		this.setScore(this.currentScore);

		// Prepare  next level after timeout
		window.setTimeout((e) => {
			this.prepareLevel();
		}, this.config.nextLevelTimeout);
	}

	/**
	 * @param {totalTime} levelTime
	 * @param {timeInterval} timeInterval
	 * @param {levelCompleteOrFailed} levelComplete
	 * @param {StartpositionofBar} startPos
	 */
	private startTimerBar(onZero: CallableFunction) {
		const levelTime = this.currentLevel.time;
		let startTime = Date.now();

		this.elmBar.classList.remove("refillBar");
		this.elmBar.classList.remove("fastBar");
		this.elmBar.style.width = "100%";

		this.currentTimeLeft = levelTime;

		this.currentTimer = window.setInterval(() => {
			if (this.levelState !== "started") {
				return;
			}

			const delta = Date.now() - startTime;

			this.currentTimeLeft = Math.max(
				-100,
				levelTime - Math.round(delta)
			); // whole seconds
			let progressTimeLeft = Math.max(0, levelTime - delta);
			const progress = Math.min(
				100,
				Math.max(
					0,
					(this.currentTimeLeft / this.currentLevel.time) * 100
				)
			);
			this.elmBar.style.width = progress + "%";
			if (this.currentTimeLeft <= -100 && !this.flipping) {
				setTimeout(() => {
					if (!this.flipping) {
						window.clearInterval(this.currentTimer);
						onZero();
					} else {
						return;
					}
				}, this.config.checkInterval);
			}
		}, this.config.checkInterval);
	}

	private emptyBar(onZero: CallableFunction) {
		const interval = Math.round((this.currentTimeLeft / 1000) * 50); // 1 second devided per points
		this.elmBar.classList.remove("refillBar");
		this.elmBar.classList.remove("fastBar");
		this.elmBar.classList.add("emptyBar");
		// this.elmBar.style.width = '0%';
		let timeLeft = this.currentTimeLeft;

		const bonus = this.currentTimeLeft * this.config.bonusPerMs;

		let timer = window.setInterval((e) => {
			timeLeft -= interval;

			const progress = Math.max(
				0,
				(timeLeft / this.currentLevel.time) * 100
			);
			this.elmBar.style.width = progress + "%";
			this.setScore(
				this.currentScore +
					(this.currentTimeLeft - timeLeft) * this.config.bonusPerMs
			);

			this.playSound("timeBonus", true);

			if (timeLeft <= 0) {
				if (this.sounds.timeBonus) this.sounds.timeBonus.loop(false);
				window.clearInterval(timer);
				onZero();
				return;
			}
		}, 50);
		return;
	}

	private setScore(score: number, source?: string) {
		this.score = score;
		this.elmScore.innerHTML = Math.round(this.score)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}

	private loadNextTileDeck(): boolean {
		if (this.currentLevel == null) {
			this.currentLevel = { ...this.allLevels[0] };
			return true;
		}

		// Last Level
		if (this.currentLevel.level < this.allLevels.length) {
			this.currentLevel = { ...this.allLevels[this.currentLevel.level] };
			this.currentLevel.time = Math.max(
				5,
				this.currentLevel.time - this.multiplier * this.config.speedUp
			);
			return true;
		}

		// Loop back to first level
		this.loops++;
		if (this.config.maxLoops > 0 && this.loops > this.config.maxLoops) {
			// Game ended, no new level
			return false;
		}

		// Next level, increase speed and start with first level
		this.currentLevel = { ...this.allLevels[0] };
		this.multiplier++;
		this.currentLevel.time = Math.max(
			5,
			this.currentLevel.time - this.multiplier * this.config.speedUp
		);

		return true;
	}

	private loadGrid() {
		const tileNames = this.currentLevel.tiles;

		// Create array with all tiles
		const tileDeck: TileDeck = [];
		tileNames.forEach((name) => {
			tileDeck.push(new Tile(this, name, 1));
			tileDeck.push(new Tile(this, name, 2));
		});

		let colCount = Math.min(
			this.config.maxColumns,
			Math.sqrt(this.currentLevel.tiles.length * 2)
		);
		let rowCount = Math.round(
			(this.currentLevel.tiles.length * 2) / colCount
		);
		switch (this.currentLevel.tiles.length) {
			case 1:
				colCount = 1;
				rowCount = 2;
				break;
			case 2:
				colCount = 2;
				rowCount = 2;
				break;
			case 3:
				colCount = 2;
				rowCount = 3;
				break;
			case 4:
				colCount = 3;
				rowCount = 3;
				break;
			case 5:
				colCount = 3;
				rowCount = 4;
				break;
			case 6:
				colCount = 3;
				rowCount = 4;
				break;
			// case 7:
			//   colCount = 2;
			//   rowCount = 7;
			//   break;
			// case 8:
			//   colCount = 4;
			//   rowCount = 4;
			//   break;
			case 9:
				colCount = 4;
				rowCount = 5;
				break;
			case 10:
				colCount = 4;
				rowCount = 5;
				break;
			// case 11:
			//   colCount = 2;
			//   rowCount = 11;
			//   break;
			// case 12:
			//     colCount = 4;
			//     rowCount = 6;
			//     break;
			// case 13:
			//   colCount = 2;
			//   rowCount = 13;
			//   break;
			// case 14:
			//     colCount = 4;
			//     rowCount = 7;
			//     break;
			// case 15:
			//     colCount = 5;
			//     rowCount = 6;
			//     break;
		}

		// Determine grid dimension

		this.tileGrid = [];
		let amountOfTilesTotal = tileDeck.length;
		let currentTile = 0;
		for (let row = 0; row < rowCount; row++) {
			this.tileGrid[row] = [];
			for (let col = 0; col < colCount; col++) {
				if (currentTile >= amountOfTilesTotal) {
					break;
				}
				// Get a random card from the deck and add to grid location
				this.tileGrid[row][col] = tileDeck.splice(
					Math.floor(Math.random() * tileDeck.length),
					1
				)[0];
				currentTile++;
			}
		}
	}

	sounds = {
		tileSelect: undefined,
		match: undefined,
		noMatch: undefined,
		timeBonus: undefined,
	};

	playSound(soundKey: string, loop: boolean = false) {
		const config = this.config as any;
		if (config[soundKey + "Sound"])
			this.platform.playSound(
				config.path + "/" + config[soundKey + "Sound"]
			);
	}

	printConfig(config: any) {
		let nice = "";
		for (const [key, value] of Object.entries(config)) {
			nice += key + "=" + value + "\n";
		}
		console.log(nice);
	}
}
